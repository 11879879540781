import clsx from "clsx";
import { ImageContentBlock, ImageObjectType } from "graphql/generated-types";
import Image from "next/image";

import ChevronLink from "../ChevronLink";

type ImageProps = {
  extraClasses?: string;
  img: ImageObjectType;
};

const ImageEl: React.FC<ImageProps> = ({ extraClasses, img }) => {
  let classes = clsx("flex-1 w-full", extraClasses);
  return (
    <Image
      className={classes}
      src={img?.rendition?.url!}
      alt={img?.rendition?.image.title!}
      width={img?.rendition?.width}
      height={img?.rendition?.height}
    />
  );
};

const ImageContent: React.FC<ImageContentBlock> = ({
  title,
  direction,
  image,
  superTitle,
  content,
  link,
  linkText,
}) => {
  return (
    <div className="flex flex-col">
      <h2 className="block-title xl:w-3/4">{title}</h2>
      <div className="flex flex-col overflow-hidden text-white rounded xl:rounded-lg xl:flex-row">
        {direction === "left" && (
          <ImageEl extraClasses="xl:mr-[-60px]" img={image} />
        )}
        <div className="z-10 flex flex-col justify-center flex-1 -mt-5 rounded xl:m-0 xl:rounded-lg bg-blue">
          <div className="p-5 pb-9 xl:p-28">
            <div className="mb-10 font-semibold tracking-wide text-xxs xl:text-sm text-cyan">
              {superTitle}
            </div>
            <div className="text-base font-light xl:leading-snug xl:text-xl">
              {content}
            </div>
            <ChevronLink url={link!} secondary extraClasses="mt-10 lg:mt-7">
              {linkText!}
            </ChevronLink>
          </div>
        </div>
        {direction === "right" && (
          <ImageEl
            extraClasses="xl:ml-[-60px] xl:order-last order-first"
            img={image}
          />
        )}
      </div>
    </div>
  );
};

export default ImageContent;
