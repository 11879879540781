import Link from "next/link";
import clsx from "clsx";

import Chevron from "@/icons/chevron.svg";

type ButtonProps = {
  children: React.ReactElement | string;
  link: string;
  secondary?: boolean;
  extraClasses?: string;
};

const Button: React.FC<ButtonProps> = ({
  children,
  link,
  secondary = false,
  extraClasses = "",
}) => {
  let classes = clsx({
    "flex items-center justify-between py-4 rounded-button px-7 group gap-2":
      true,
    "bg-blue text-white border border-border": secondary,
    "bg-white": !secondary,
    [extraClasses]: extraClasses,
  });
  return (
    <Link className={classes} href={link}>
      <span>{children}</span>{" "}
      <Chevron
        className={clsx(
          "h-4 group-hover:translate-x-1 transition-transform ",
          secondary ? "text-cyan" : "text-blue"
        )}
      />
    </Link>
  );
};

export default Button;
