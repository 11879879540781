
import { Field, Fieldset, Label, Legend } from '@headlessui/react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';

type CheckboxesProps = {
    label: string;
    formKey: string;
    required?: boolean;
    options: string[];
    control: any;
    helpText?: string;
};


export default function CheckboxGroup({
    label,
    formKey,
    required,
    options,
    helpText,
}: CheckboxesProps) {
    const { register, formState: { errors } } = useFormContext(

    )
    return (
        <Fieldset>
            <Legend className='form-label'>{label}{required && '*'}</Legend>
            {helpText && <p className="form-help">{helpText}</p>}
            <div className="flex flex-col">
                {options.map((option) => (
                    <Field key={option} className="flex items-center relative">
                        <input
                            {...register(formKey, {
                                required: { value: required || false, message: "Please select at least one option" }
                            })}
                            id={option}
                            value={option}
                            type='checkbox'
                            className="peer hidden"
                        />
                        <div className='pointer-events-none absolute block size-5 border bg-white transition peer-checked:bg-blue-light'>
                            <svg className="stroke-white transition" viewBox="0 0 14 14" fill="none">
                                <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <Label htmlFor={option} className='pl-7'>
                            {option}
                        </Label>
                    </Field>
                ))}
            </div>
            <ErrorMessage
                name={formKey}
                errors={errors}
                render={({ message }) => <p className="text-red-500 mt-2">{message}</p>}
            />
        </Fieldset>
    )

}
