import { ExternalLinkBlock, InternalLinkBlock, QuickLinksBlock } from "graphql/generated-types";

import Chevron from "@/icons/chevron.svg";

type LinkTypes = InternalLinkBlock | ExternalLinkBlock;

const QuickLinks = ({ block: { title, links } }: { block: QuickLinksBlock }) => {
  const blockLinks = links as LinkTypes[]
  return (
    <div>
      {title && <h3 className="block-title">{title}</h3>}
      <div className="flex flex-col-reverse gap-8 lg:gap-14 lg:flex-row">
        {blockLinks && blockLinks.map(block => {
          return (
            <a
              key={block.id!}
            href={block.href}
            className="flex items-center gap-3 py-4 text-base font-medium align-middle transition-colors border rounded-lg group border-border px-7 hover:bg-blue hover:text-white"
            >{block.text}
            <Chevron
        className={
          "h-5 group-hover:translate-x-1 transition-transform text-cyan"
        }
      />
            </a>
          )
        })}
      </div>
    </div>
  );
};

export default QuickLinks;
