import { Swiper, SwiperSlide } from "swiper/react";
import { useMedia } from "react-use";
import {
  ResourcesBlock,
  Resource as ResourceType,
} from "graphql/generated-types";
import Resource from "../Resource";

import "swiper/css";

const FeaturedResources: React.FC<ResourcesBlock> = ({
  title,
  featured,
  resources,
}) => {
  const isMobile = useMedia("(max-width: 1023px)", false);

  return (
    <div>
      <div className="block-title">{title}</div>
      {isMobile ? (
        <Swiper spaceBetween={34} slidesPerView={1.15} className="!-mx-6 !px-6">
          {resources?.map((resource) => (
            <SwiperSlide key={resource!.id}>
              <Resource
                resource={resource as ResourceType}
                featured={featured || false}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="grid grid-cols-4 gap-x-10 gap-y-20">
          {resources?.map((resource) => (
            <Resource
              key={resource!.id}
              resource={resource as ResourceType}
              featured={featured || false}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FeaturedResources;
