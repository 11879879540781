import { RichtextWithHeadingBlock } from "graphql/generated-types";
import ColumnBlock from "./ColumnBlock";

const RichText = ({
  block: { heading, richtext },
}: {
  block: RichtextWithHeadingBlock;
}) => {
  return (
    <ColumnBlock
      left={
        heading && (
          <h3 className="text-lg leading-tight -translate-y-1 2xl:-translate-y-3 2xl:text-2xl">
            {heading}
          </h3>
        )
      }
      right={
        <div
          className="prose max-w-none"
          dangerouslySetInnerHTML={{ __html: richtext }}
        ></div>
      }
    />
  );
};

export default RichText;
