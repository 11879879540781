import { ProfileBlock, ProfileListBlock } from "graphql/generated-types";
import Image from "next/image";
import ColumnBlock from "./ColumnBlock";
import RichTextWithHeading from "./RichtextWithHeading";

type ProfileProps = {
  profile: ProfileBlock;
};

const Profile: React.FC<ProfileProps> = ({
  profile: { title, content, image },
}) => {
  return (
    <ColumnBlock
      left={
        <Image
          className="rounded"
          src={image?.rendition?.url!}
          alt={image?.rendition?.image.title!}
          width={image?.rendition?.width}
          height={image?.rendition?.height}
        />
      }
      right={
        <div
          className="prose max-w-none"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      }
    />
  );
};

const ProfileList: React.FC<ProfileListBlock> = ({
  title,
  intro,
  profiles,
}) => {
  return (
    <div className="flex flex-col gap-24 lg:gap-28">
      {/* @ts-ignore cbf making a subtype, it's basically the same thing */}
      <RichTextWithHeading block={{ heading: title!, richtext: intro! }} />
      {profiles?.map((profile, idx) => (
        <Profile key={idx} profile={profile as ProfileBlock} />
      ))}
    </div>
  );
};

export default ProfileList;
