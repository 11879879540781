import FormInput from "@/components/forms/Input";
import { CheckboxFieldBlock, ChoiceFieldBlock, FieldBlock, FileFieldBlock, FormBlock } from "graphql/generated-types";
import { useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import CheckboxInput from "../forms/Checkbox";
import CheckboxGroup from "../forms/CheckboxGroup";
import Dropdown from "../forms/Dropdown";
import FileInput from "../forms/FileInput";
import Radios from "../forms/Radios";
import ColumnBlock from "./ColumnBlock";

type FieldBlockTypes = ChoiceFieldBlock | FieldBlock | FileFieldBlock | CheckboxFieldBlock;

const CustomForm = ({
    block,
    pageId,
}: {
    block: FormBlock;
    pageId?: string | null;
}) => {
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const titleRef = useRef<HTMLHeadingElement>(null);
    const methods = useForm();
    const { handleSubmit, control } = methods;

    // @ts-ignore
    const onSubmit: SubmitHandler = async (data) => {
        // Stupid but gets us what we need
        const endpoint = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT!.replace('/graphql/', `/form-submission/${pageId}/`);

        const body = new FormData();
        for (const [key, value] of Object.entries(data)) {
            if (value instanceof FileList || value instanceof Array) {
                for (let i = 0; i < value.length; i++) {
                    body.append(key, value[i]);
                }
            } else {
                // @ts-ignore stfu
                body.append(key, value);
            }
        }

        try {
          const response = await fetch(endpoint, {
              method: 'POST',
              body: body,
          });
          if (response.status !== 200) {
              setError(true);
              return;
          }
          const result = await response.json();
        if (result['ok']) {
            setSubmitted(true);
            titleRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      } catch (error) {
        console.error('Error submitting form', error);
          setError(true);
      }
    }
    // Renders form field based on block
    const renderField = (field: FieldBlockTypes) => {
        switch (field.__typename) {
            case "FieldBlock":
                return <FormInput
                    key={block.id}
                    label={field.fieldLabel}
                    type={field.fieldType}
                    formKey={field.fieldName}
                    required={field.isRequired}
                    control={control}
                    helpText={field.helpText!}
                    placeholder={field.placeholder!}
                    defaultValue={field.defaultValue!}
                />;
            case "CheckboxFieldBlock":
                return <CheckboxInput
                    key={block.id}
                    label={field.fieldLabel}
                    required={field.isRequired}
                    formKey={field.fieldName}
                    control={control}
                    helpText={field.helpText!}
                />;
            case "FileFieldBlock":
                return <FileInput
                    key={block.id}
                    label={field.fieldLabel}
                    formKey={field.fieldName}
                    required={field.isRequired}
                    helpText={field.helpText!}
                />;
            case "ChoiceFieldBlock":
                switch (field.fieldType) {
                    case "select":
                        return <Dropdown
                            key={block.id}
                            label={field.fieldLabel}
                            formKey={field.fieldName}
                            required={field.isRequired}
                            options={field.choices as string[]}
                            control={control}
                            helpText={field.helpText!}
                        />;
                    case "radio":
                        return <Radios
                            key={block.id}
                            label={field.fieldLabel}
                            required={field.isRequired}
                            formKey={field.fieldName}
                            options={field.choices as string[]}
                            control={control}
                            helpText={field.helpText!}
                        />;
                    case "checkbox":
                        return <CheckboxGroup
                            key={block.id}
                            label={field.fieldLabel}
                            formKey={field.fieldName}
                            required={field.isRequired}
                            options={field.choices as string[]}
                            control={control}
                            helpText={field.helpText!}
                        />;
                }
        }
    }

    return (<ColumnBlock
        left={
            (
                <h3 ref={titleRef} className="text-lg leading-tight -translate-y-1 2xl:-translate-y-3 2xl:text-2xl">
                    {block.formTitle}
                </h3>
            )
        }
        right={
            error ? <div className='prose max-w-none'><p>Oops! Something went wrong processing your request. Please try again.</p></div> :
            submitted ? <div className='prose max-w-none'><p>{block.thankYouMessage}</p></div> :
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10" >
                        {block.formFields!.map((block) => renderField(block as FieldBlockTypes))}
                        <button type="submit" className="self-start underline cursor-pointer decoration-cyan underline-offset-4 decoration-2 text-[24px]">Submit</button>
                    </form>
                </FormProvider>
        }
    />
    );
};

export default CustomForm;
