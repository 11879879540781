import Image from "next/image";
import { Resource } from "graphql/generated-types";

import clsx from "clsx";
import ChevronLink from "./ChevronLink";

const Resource = ({
  resource: { title, resourceType, image, url },
  featured = false,
}: {
  resource: Resource;
  featured?: boolean;
}) => {
  return (
    <div>
      <div className={clsx("relative mb-7", featured ? "h-96" : "h-72")}>
        <Image
          className="absolute object-cover w-full h-full rounded"
          src={image?.rendition?.url!}
          width={image?.rendition?.width}
          height={image?.rendition?.height}
          alt={image?.rendition?.image.title!}
        />
      </div>
      <div>
        <div className="mb-2 font-bold leading-4 tracking-wide uppercase text-xxs lg:text-sm text-blue-lightest">
          {resourceType}
        </div>
        <div className="mb-10 text-base leading-tight lg:text-lg">{title}</div>
        <ChevronLink url={url}>Read more</ChevronLink>
      </div>
    </div>
  );
};

export default Resource;
