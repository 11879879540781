import { Checkbox, Description, Field, Label } from '@headlessui/react';
import { ErrorMessage } from '@hookform/error-message';
import {
    useController
} from "react-hook-form";

type CheckboxProps = {
    label: string;
    formKey: string;
    required?: boolean;
    control: any;
    helpText?: string;
};


const CheckboxInput = ({ label, required, formKey, control, helpText }: CheckboxProps) => {
    const {
        field: { onChange },
        formState: { errors },
    } = useController({
        name: formKey,
        control,
        rules: { required:  { value: required || false, message: "This field is required" }, }
    });
    return (
        <Field className="flex flex-col gap-2">
            <Label className="form-label">{label}{required && '*'}</Label>
            {helpText && <Description className='form-help'>{helpText}</Description>}
            <Checkbox
                onChange={onChange}
                name={formKey}
                className="group block size-5 border bg-white"
            >
                <svg className="stroke-blue-light opacity-0 transition group-data-[checked]:opacity-100" viewBox="0 0 14 14" fill="none">
                    <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </Checkbox>
            <ErrorMessage
                errors={errors}
                name={formKey}
                render={({ message }) => <p className="text-red-500">{message}</p>}
            />
        </Field>
    );
}

export default CheckboxInput;
