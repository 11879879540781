import Chevron from "@/icons/chevron.svg";
import { Description, Field, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from "@headlessui/react";
import { ErrorMessage, FieldValuesFromFieldErrors } from "@hookform/error-message";
import {
  Control,
  FieldErrors,
  FieldName,
  FieldValues,
  Path,
  useController
} from "react-hook-form";

type DropdownProps<T extends FieldValues> = {
  label: string;
  formKey: string;
  required?: boolean;
  options: string[];
  control: Control<T>;
  helpText?: string
};

const Dropdown = <T extends FieldValues>({
  label,
  formKey,
  required,
  options,
  control,
  helpText,
}: DropdownProps<T>) => {
  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({
    name: formKey as Path<T>,
    control,
    rules: { required:  { value: required || false, message: "This field is required" }, }
  });

  return (
    <Field as="div"  className="flex flex-col">
      <Label className="form-label" htmlFor={formKey}>
        {label}{required && "*"}
      </Label>
      <Listbox value={value} onChange={onChange} as="div" className="relative">
        <ListboxButton className="flex items-center justify-between w-full form-input">
          <span className="truncate">
            {options.find((option) => option === value) || "Please select"}
          </span>
          <Chevron className="w-3 h-3 transition-transform rotate-90 text-cyan ui-open:-rotate-90" />
        </ListboxButton>
        <Transition
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ListboxOptions className="absolute z-10 w-full mt-3 bg-white form-input">
            {options.map((option) => (
              <ListboxOption
                key={option}
                value={option}
                className="py-2 border-b border-[#BDD4E9] cursor-pointer last:border-0 hover:underline underline-offset-[6px]"
              >
                {option}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </Listbox>
      {helpText && <Description className='form-help mt-2'>{helpText}</Description>}
      <ErrorMessage
        name={formKey as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>}
        errors={errors}
        render={({ message }) => <p className="text-red-500 mt-2">{message}</p>}
      />
    </Field>
  );
};

export default Dropdown;
