import { ImageObjectType, LogoListBlock } from "graphql/generated-types";
import Image from "next/image";
import ColumnBlock from "./ColumnBlock";

type LogoProps = {
  image: ImageObjectType;
};

const Logo: React.FC<LogoProps> = ({ image }) => {
  return (
    <Image
      src={image?.rendition?.url!}
      alt={image?.rendition?.image.title!}
      width={image?.rendition?.width}
      height={image?.rendition?.height}
    />
  );
};

const LogoList = ({ block: { title, logos } }: { block: LogoListBlock }) => {
  return (
    <ColumnBlock
      left={
        <h3 className="text-lg leading-snug -translate-y-5 lg:text-2xl">
          {title}
        </h3>
      }
      right={
        <div className="grid grid-cols-3 lg:gap-y-20 lg:gap-x-24 gap-y-8 gap-x-11">
          {logos?.map((logo) => (
            <Logo image={logo!} key={logo!.id} />
          ))}
        </div>
      }
    />
  );
};

export default LogoList;
