import Image from "next/image";
import { StatBlock, StatsBlock } from "graphql/generated-types";

type StatProps = {
  stat: {
    stat: string;
    statDescription: string;
  };
};

const Stat: React.FC<StatProps> = ({ stat: { stat, statDescription } }) => {
  return (
    <div className="flex flex-col justify-between pl-2 pr-5 border-l lg:px-6">
      <h3 className="mb-6 -mt-2 text-2xl font-light leading-none lg:mb-20 lg:-mt-4 lg:text-4xl text-blue-light">
        {stat}
      </h3>
      <p className="text-sm leading-tight lg:text-lg">{statDescription}</p>
    </div>
  );
};

const Stats: React.FC<StatsBlock> = ({ title, image, stats }) => {
  return (
    <div>
      <h3 className="block-title">{title}</h3>
      <div className="flex flex-col-reverse gap-20 lg:gap-10 lg:flex-row">
        <div className="grid flex-1 grid-cols-2 gap-y-20 lg:gap-y-16">
          {stats?.map((stat, idx) => (
            <Stat key={idx} stat={stat as StatBlock} />
          ))}
        </div>
        <div className="flex-1">
          <Image
            className="w-full h-full rounded lg:rounded-lg"
            src={image?.rendition?.url!}
            alt={image?.rendition?.image.title!}
            width={image?.rendition?.width}
            height={image?.rendition?.height}
          />
        </div>
      </div>
    </div>
  );
};

export default Stats;
