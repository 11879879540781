import CircledChevron from "@/icons/circled-chevron.svg";
import CircledChevronLight from "@/icons/circled-chevron-light.svg";
import clsx from "clsx";

type LinkProps = {
  children: React.ReactElement | string;
  url: string;
  extraClasses?: string;
  secondary?: boolean;
};

const ChevronLink: React.FC<LinkProps> = ({
  children,
  url,
  secondary,
  extraClasses = "",
}) => {
  return (
    <a
      className={clsx(
        "flex items-center justify-start gap-5 lg:gap-6 w-max",
        extraClasses
      )}
      href={url}
    >
      {secondary ? (
        <CircledChevronLight className="w-8 h-8 lg:w-10 lg:h-10" />
      ) : (
        <CircledChevron className="w-8 h-8 lg:w-10 lg:h-10" />
      )}
      {children}
    </a>
  );
};

export default ChevronLink;
