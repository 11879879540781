
import { Description, Field, Input, Label, Textarea } from "@headlessui/react";
import { ErrorMessage, FieldValuesFromFieldErrors } from "@hookform/error-message";
import {
  Control,
  FieldErrors,
  FieldName,
  FieldValues,
  Path,
  PathValue,
  useController
} from "react-hook-form";

type InputProps<T extends FieldValues> = {
  label: string;
  type: string;
  formKey: string;
  required?: boolean;
  control: Control<T>;
  defaultValue?: PathValue<T, Path<T>>;
  placeholder?: string;
  helpText?: string;
};

const FormInput = <T extends FieldValues>({
  label,
  type,
  required,
  formKey,
  control,
  defaultValue,
  placeholder,
  helpText,
}: InputProps<T>) => {
  const {
    field,
    formState: { errors },
  } = useController({
    name: formKey as Path<T>,
    control,
    defaultValue: defaultValue || "" as PathValue<T, Path<T>>,
    rules: { required:  { value: required || false, message: "This field is required" }, }
  });

  if (type === "hidden") return <input {...field} type={type} />;

  return (
    <Field as="div" className="flex flex-col">
      <Label className="form-label">
        {label}{required && "*"}
      </Label>
      {helpText && <Description className='form-help mb-2'>
        {helpText}
        </Description>}
      {type == "textarea" ? (
        <Textarea className="form-input" {...field} rows={10} placeholder={placeholder} />
      ) : (
        <Input className="form-input" type={type} {...field} placeholder={placeholder} />
      )}
      <ErrorMessage
        // what the actual fuck, it's a string
        name={formKey as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>}
        errors={errors}
        render={({ message }) => <p className="text-red-500 mt-2">{message}</p>}
      />
    </Field>
  );
};

export default FormInput;
