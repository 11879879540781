import {
  FeaturedContentBlock,
  FormBlock,
  ImageContentBlock,
  ImageObjectType,
  LogoListBlock,
  ProfileListBlock,
  QuickLinksBlock,
  ResourcesBlock,
  RichtextWithHeadingBlock,
  RichtextWithImageBlock,
  StatsBlock,
  StreamFieldInterface,
  VideoEmbedBlock,
} from "graphql/generated-types";
import FeaturedResources from "./FeaturedResources";
import CustomForm from "./FormBlock";
import ImageContent from "./ImageContent";
import LogoList from "./LogoList";
import ProfileList from "./ProfileList";
import QuickLinks from "./QuickLinks";
import RichtextWithHeading from "./RichtextWithHeading";
import RichtextWithImage from "./RichtextWithImage";
import Stats from "./Stats";
import VideoEmbed from "./VideoEmbed";

type AliasedProfileListBlock = ProfileListBlock & {
  heading: string;
};

type AliasedRichtextWithImageBlock = RichtextWithImageBlock & {
  imageTitle: string;
  captionedImage: StreamFieldInterface;
};

type AliasedImageContentBlock = ImageContentBlock & {
  imageTitle: string;
  contentImage: ImageObjectType;
};

type AliasResourcesBlock = ResourcesBlock & {
  resourcesTitle: string;
};

type AliasedStatsBlock = StatsBlock & {
  statsTitle: string;
  statsImage: ImageObjectType;
};

export type BlockTypes =
  | FeaturedContentBlock
  | RichtextWithHeadingBlock
  | AliasedRichtextWithImageBlock
  | AliasedProfileListBlock
  | LogoListBlock
  | AliasedImageContentBlock
  | AliasResourcesBlock
  | AliasedStatsBlock
  | QuickLinksBlock
  | VideoEmbedBlock
  | FormBlock;

type Props = {
  pageId?: string | null;
  blocks: [BlockTypes];
};

const BlockRouter = ({ blocks, pageId }: Props) => {
  const renderBlock = (block: BlockTypes) => {
    switch (block.__typename) {
      case "RichtextWithHeadingBlock":
        return <RichtextWithHeading key={block.id} block={block} />;
      case "RichtextWithImageBlock":
        return (
          <RichtextWithImage
            key={block.id}
            {...block}
            image={block.captionedImage}
          />
        );
      case "LogoListBlock":
        return <LogoList key={block.id} block={block} />;
      case "ProfileListBlock":
        return <ProfileList key={block.id} {...block} title={block.heading} />;
      case "ImageContentBlock":
        return (
          <ImageContent
            key={block.id}
            {...block}
            title={block.imageTitle}
            image={block.contentImage}
          />
        );
      case "ResourcesBlock":
        return (
          <FeaturedResources
            key={block.id}
            {...block}
            title={block.resourcesTitle}
          />
        );
      case "StatsBlock":
        return (
          <Stats
            key={block.id}
            {...block}
            title={block.statsTitle}
            image={block.statsImage}
          />
        );
      case "VideoEmbedBlock":
        return <VideoEmbed key={block.id} block={block} />;
      case "QuickLinksBlock":
        return <QuickLinks key={block.id} block={block as QuickLinksBlock} />;
      case "FormBlock":
        return <CustomForm key={block.id} block={block} pageId={pageId} />;
      default:
        throw new Error(`Unknown block type: ${block.__typename}`);
    }
  };

  return (
    <div className="flex flex-col gap-24 lg:gap-48">
      {blocks.map(renderBlock)}
    </div>
  );
};

export default BlockRouter;
