import clsx from "clsx";
import React from "react";

const ColumnBlock = ({
  left,
  right,
}: {
  left: React.ReactNode;
  right: React.ReactNode;
}) => {
  return (
    <div className="grid w-full md:gap-10 md:grid-cols-3">
      <div className="md:col-span-1">{left}</div>
      <div className={clsx("md:col-span-2 md:mt-0", left ? "mt-8" : "")}>
        {right}
      </div>
    </div>
  );
};

export default ColumnBlock;
