import {
  CaptionedImageBlock,
  RichtextWithImageBlock,
} from "graphql/generated-types";
import CaptionedImage from "./CaptionedImage";
import ColumnBlock from "./ColumnBlock";

const RichText: React.FC<RichtextWithImageBlock> = ({ image, richtext }) => {
  return (
    <ColumnBlock
      left={<CaptionedImage block={image as CaptionedImageBlock} />}
      right={
        <div
          className="prose max-w-none"
          dangerouslySetInnerHTML={{ __html: richtext }}
        />
      }
    />
  );
};

export default RichText;
