import { Field, Fieldset, Label, Legend, Radio, RadioGroup } from '@headlessui/react';
import { ErrorMessage } from '@hookform/error-message';
import { useController } from 'react-hook-form';

type RadioProps = {
    label: string;
    formKey: string;
    required?: boolean;
    options: string[];
    control: any;
    helpText?: string;
};


export default function Radios({
    label,
    formKey,
    required,
    options,
    control,
    helpText,
}: RadioProps) {
    const {
        field: { value, onChange },
        formState: { errors },
    } = useController({
        name: formKey,
        control,
        defaultValue: '',
        rules: { required: { value: required || false, message: "Please select an option" } },
    });
    return (
    <Fieldset>
        <Legend className='form-label'>{label}{required && '*'}</Legend>
        {helpText && <p className="form-help">{helpText}</p>}
        <RadioGroup value={value} onChange={onChange}>
            {options.map((option) => (
                <Field key={option} className="flex items-baseline gap-2">
                    <Radio value={option} className="group flex size-5 items-center justify-center rounded-full border bg-whit">
                    <span className="invisible size-3 rounded-full bg-blue-light group-data-[checked]:visible" />
                    </Radio>
                    <Label>{option}</Label>
                </Field>
            ))}
        </RadioGroup>
        <ErrorMessage
            name={formKey}
            errors={errors}
            render={({ message }) => <p className="text-red-500 mt-2">{message}</p>}
        />
    </Fieldset>
    )
}
