import {
  HomePage,
  InternalLinkBlock,
  LandingPage,
} from "graphql/generated-types";
import Image from "next/image";
import Button from "./Button";
import clsx from "clsx";

type HeroProps = {
  page: HomePage | LandingPage;
};

const Hero: React.FC<HeroProps> = ({ page }) => {
  const heroLinks = page?.heroLinks! as InternalLinkBlock[];
  const introLinks = page?.introLinks! as InternalLinkBlock[];
  return (
    <>
      <div className="relative pb-16 lg:h-screen lg:min-h-[760px] h-[50vh]">
        <Image
          className="absolute z-[-1] w-full h-full object-cover"
          src={page?.heroImage?.rendition?.url!}
          alt={page?.heroImage?.rendition?.image?.title!}
          height={page?.heroImage?.rendition?.height}
          width={page?.heroImage?.rendition?.width}
        />
        <div className="absolute z-[-1] inset-0 bg-[#A6A6A6] mix-blend-multiply"></div>
      </div>
      <div className="mb-20 -mt-16 lg:-mt-28 rounded-lg lg:rounded-[120px] pb-12 lg:py-40 bg-blue lg:mb-44">
        <div className="container lg:min-h-[760px] flex flex-col justify-end h-full pt-9 lg:pb-48 lg:absolute lg:top-0 lg:left-1/2 lg:-translate-x-1/2">
          <h3 className="xl:w-3/4 mb-11 text-white lg:text-[60px] 2xl:text-[70px] text-[30px] leading-snug lg:leading-tight font-light">
            {page.heroTagline}
          </h3>
          {heroLinks.length > 0 && (
            <div
              className={clsx(
                "grid gap-4 lg:gap-x-10 lg:gap-y-7 md:grid-cols-2 2xl:grid-cols-4 grid-flow-col",
                introLinks.length > 0 ? "to-lg:mb-32" : "to-lg:mb-10"
              )}
            >
              {heroLinks?.map((link) => (
                <Button
                  key={link!.id}
                  link={link?.href}
                  extraClasses="col-start-1 max-w-[330px]"
                >
                  {link?.text}
                </Button>
              ))}
            </div>
          )}
        </div>
        <div className="container text-white">
          {introLinks.length > 0 && (
            <div className="grid gap-4 mb-20 md:grid-cols-2 lg:grid-cols-4 lg:mb-32 lg:gap-10">
              {introLinks?.map((link) => (
                <Button key={link!.id} link={link?.href} secondary>
                  {link?.text}
                </Button>
              ))}
            </div>
          )}
          <div className="mb-10 text-base lg:leading-snug lg:text-2xl">
            {page.introText}
          </div>
          <a
            className="underline decoration-cyan underline-offset-8"
            href={page?.learnMoreLink!}
          >
            {page.learnMoreLabel}
          </a>
        </div>
      </div>
    </>
  );
};

export default Hero;
