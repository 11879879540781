
import { Description, Field, Label } from "@headlessui/react";
import { ErrorMessage } from "@hookform/error-message";
import {
    useFormContext
} from "react-hook-form";

type FileInputProps = {
    label: string;
    formKey: string;
    required?: boolean;
    helpText?: string;
};

const FileInput = ({
    label,
    required,
    formKey,
    helpText,
}: FileInputProps) => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <Field as="div" className="flex flex-col">
            <Label className="form-label" htmlFor={formKey}>
                {label}{required && "*"}
            </Label>
            {helpText && <Description className='form-help mb-2'>
                {helpText}
            </Description>}
            <input className="form-input" type='file' {...register(formKey, {
                required: { value: required || false, message: "Please upload a file" }
            })} />
            <ErrorMessage
                name={formKey}
                errors={errors}
                render={({ message }) => <p className="text-red-500 mt-2">{message}</p>}
            />
        </Field>
    );
};

export default FileInput;
