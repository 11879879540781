import { CaptionedImageBlock } from "graphql/generated-types";
import Image from "next/image";

const CaptionedImage = ({
  block: { image, caption },
}: {
  block: CaptionedImageBlock;
}) => {
  return (
    <div className="flex flex-col gap-3">
      <Image
        className="w-full rounded"
        src={image?.rendition?.url!}
        alt={image?.rendition?.image.title!}
        width={image?.rendition?.width}
        height={image?.rendition?.height}
      />
      {caption && <p className="text-xs lg:text-sm">{caption}</p>}
    </div>
  );
};

export default CaptionedImage;
