import { VideoEmbedBlock } from "graphql/generated-types";
import ColumnBlock from "./ColumnBlock";

const VideoEmbed = ({ block: { title, url } }: { block: VideoEmbedBlock }) => {
  return (
    <ColumnBlock
      left={
        title && (
          <h3 className="text-lg leading-tight -translate-y-1 2xl:-translate-y-3 2xl:text-2xl">
            {title}
          </h3>
        )
      }
      right={
        <div className="w-full" dangerouslySetInnerHTML={{ __html: url }} />
      }
    />
  );
};

export default VideoEmbed;
